import React, { useState, FormEvent } from 'react';
import { ChevronRight, Crosshair, Shield, Radio } from 'lucide-react';

const TacticalFPVWebsite: React.FC = () => {
  const [formStatus, setFormStatus] = useState<string>('');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const data = new FormData(form);

    setFormStatus('Transmitting...');

    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        body: JSON.stringify(Object.fromEntries(data)),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setFormStatus('Message transmitted successfully!');
        form.reset();
      } else {
        setFormStatus('Transmission failed. Please try again.');
      }
    } catch (error) {
      setFormStatus('An error occurred. Please try again later.');
    }
  };

  const services = [
    {
      name: 'Basic Training',
      icon: <Radio />,
      description: 'Pilots will start with essential FPV (First Person View) and Line of Sight flight techniques, along with a brief FAA Part 107 overview. The basics of UAV capabilities, environmental impacts, safe operating procedures, and preflight checks will be covered. This foundational training ensures pilots are equipped with the knowledge to operate Micro UAVs confidently.'
    },
    {
      name: 'Advanced Maneuvers',
      icon: <Crosshair />,
      description: 'Pilots will move on to mastering advanced 3D flight path decision-making and maneuvering UAVs in confined, low-light environments. Techniques like drone-on-drone engagement and ground target engagement will be introduced, helping pilots refine their skills for more complex, real-world scenarios.'
    },
    {
      name: 'Tactical Operations',
      icon: <Shield />,
      description: 'This stage focuses on tactical applications, including crew resource management (CRM), radio communication, and advanced decision-making. Pilots will practice using UAVs in mission-oriented settings and receive certification upon course completion, along with a take-home Micro UAV FPV Training Kit for continued learning.'
    }
  ];

  return (
    <div className="font-mono text-green-400 bg-gray-900">
      {/* Header */}
      <header className="bg-black border-b border-green-500 p-4">
        <nav className="container mx-auto flex justify-between items-center">
          <div className="text-xl font-bold flex items-center">
          <img src="/images/logo.svg" alt="Tactical FPV Logo" className="mr-2 h-6 w-6" />
            Tactical FPV
          </div>
          <ul className="flex space-x-4">
            {['Home', 'Services', 'About', 'Contact'].map((item, index) => (
              <li key={index}>
                <a href={`#${item.toLowerCase()}`} className="hover:text-green-300 flex items-center">
                  <ChevronRight className="mr-1" size={16} />
                  {item}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </header>

      {/* Main Content */}
      <main>
        {/* Hero Section with Video Background */}
        <section id="hero" className="relative h-screen flex items-center justify-center overflow-hidden">
          <video 
            autoPlay 
            loop 
            muted 
            className="absolute z-10 w-auto min-w-full min-h-full max-w-none"
          >
            <source src="video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="absolute z-20 inset-0 bg-black opacity-50"></div>
          <div className="relative z-30 text-center">
            <h1 className="text-5xl font-bold mb-4 text-white">Master the Art of FPV Drone Flight</h1>
            <p className="mb-8 text-xl text-gray-300">Professional training for tactical and recreational drone pilots</p>
            <a href="#contact" className="bg-green-600 text-black px-8 py-3 rounded hover:bg-green-500 transition duration-300 font-bold text-lg">
              INITIATE TRAINING
            </a>
          </div>
        </section>

        {/* Services Section */}
        <section id="services" className="py-16 bg-gray-800">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold text-center mb-8 flex items-center justify-center">
              <Shield className="mr-2" />
              Our Services
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {services.map((service, index) => (
                <div key={index} className="bg-gray-900 p-6 rounded border border-green-500 shadow-lg shadow-green-500/20">
                  <div className="flex items-center mb-4">
                    {service.icon}
                    <h3 className="text-xl font-semibold ml-2">{service.name}</h3>
                  </div>
                  <p className="text-gray-400">{service.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* About Section */}
        <section id="about" className="py-16 bg-gray-900">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold text-center mb-8 flex items-center justify-center">
              <Radio className="mr-2" />
              About Tactical FPV
            </h2>
            <p className="text-center max-w-2xl mx-auto text-gray-300">
              We are an elite team of drone operators providing cutting-edge FPV training. 
              Our courses are designed for both novice pilots and seasoned professionals seeking to push their limits.
            </p>
          </div>
        </section>

        {/* Contact Section */}
        <section id="contact" className="py-16 bg-gray-800">
          <div className="container mx-auto max-w-md">
            <h2 className="text-3xl font-bold text-center mb-8 flex items-center justify-center">
              <Radio className="mr-2" />
              Contact HQ
            </h2>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <input name="name" type="text" placeholder="Name" className="w-full p-2 bg-gray-900 border border-green-500 rounded text-green-400" required />
              <input name="email" type="email" placeholder="Secure Email" className="w-full p-2 bg-gray-900 border border-green-500 rounded text-green-400" required />
              <textarea name="message" placeholder="Mission Details" className="w-full p-2 bg-gray-900 border border-green-500 rounded text-green-400 h-32" required></textarea>
              <button type="submit" className="w-full bg-green-600 text-black py-2 rounded hover:bg-green-500 transition duration-300 font-bold">TRANSMIT</button>
            </form>
            {formStatus && <p className="mt-4 text-center">{formStatus}</p>}
            <div className="mt-6 text-center text-sm text-gray-400 space-y-2">
              <p>Price available upon request.</p>
              <p>Onsite training is available.</p>
              <p>Please contact UStacticalfpv@gmail.com for pricing and scheduling information.</p>
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-black text-green-400 py-4 text-center border-t border-green-500">
        <p>&copy; 2024 Tactical FPV. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default TacticalFPVWebsite;
